export enum toastType {
  SUCCESS = 'success',
  DANGER = 'danger'
}

export type Toast = {
  id: string,
  type: toastType,
  text: string,
  duration?: number | null,
  icon?: string,
}

export interface ToastProps {
  toast: Toast,
  smoothAppearance?: boolean,
}
