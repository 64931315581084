import { useSubsrbStore } from './subsrb';
import type { UserDto } from '~/dto';

export const useAuthStore = defineStore('auth', () => {
  const user = ref<UserDto | null>(null);
  const token = ref<string | null>(null);

  const subscrbStore = useSubsrbStore();

  const setToken = (newToken: string): void => {
    token.value = newToken;
    const { $auth } = useNuxtApp();
    $auth.setToken(newToken);
  };

  const setUserData = (data: UserDto): void => {
    user.value = data;
  };

  const clearUserData = (): void => {
    user.value = null;
    token.value = null;
    subscrbStore.patchSubscrb(null);
  };

  const clearAuth = (): void => {
    clearUserData();
    const { $auth, $localePath } = useNuxtApp();
    $auth.clearToken();
    navigateTo($localePath('/auth/signin'));
  };

  const fetchUser = async (): Promise<void> => {
    try {
      const {
        $authApi: authApi,
        $auth,
      } = useNuxtApp();

      const token = $auth.getToken();
      
      if (!token) throw new Error('Error @fetchUser: empty token');
      const { result, data } = await authApi.fetchMeApi();

      if (result) {
        setUserData(data.user);
        subscrbStore.patchSubscrb(data?.subscription || null);
      } else throw new Error('Error @fetchMeApi: invalid request');
    } catch (error) {
      console.error(error);
      clearUserData();
    }
  };

  return {
    user,
    setToken,
    setUserData,
    clearUserData,
    fetchUser,
    clearAuth,
  };
});
