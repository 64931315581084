import { defineNuxtPlugin } from '#app';
import mixpanel from 'mixpanel-browser';

export default defineNuxtPlugin(() => {
  mixpanel.init('38622f4c86f7f6ba6eafb6a4469d91a5');

  return {
    provide: {
      mixpanel,
    },
  };
});