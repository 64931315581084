<template>
  <SystemLayout>

    <template #status>
      {{ error?.statusCode || 404 }}
    </template>

    {{ t(`errors.${error?.statusCode}`) }}
  </SystemLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

const { t } = useI18n();

defineProps({
  error: Object as () => NuxtError
})

definePageMeta({
  layout: 'system'
});
</script>