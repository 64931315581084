<template>
  <div
    ref="toast"
    :class="[
      $style.toast,
      $style[toastData.type],
    ]"
    @click="closeModal"
  >
    <div :class="$style.text">
      {{ toastData.text }}
    </div>
    <button
      :class="$style.close"
    >
      <Icon
        :class="$style.closeIcon"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, toRefs } from 'vue';
import { toastType, type ToastProps } from '@/components/ui/Toast/index';
import CheckIcon from '@/components/ui/Icons/Check.vue';
import CrossIcon from '@/components/ui/Icons/Cross.vue';

const { $anime } = useNuxtApp();
const props = defineProps<ToastProps>();
const emit = defineEmits(['close']);

const toast = ref();

const { toast: toastData } = toRefs<ToastProps>(props);
const Icon = computed(() => toastData.value.type === toastType.SUCCESS ? CheckIcon : CrossIcon);
const duration = toastData?.value.duration;

const closeModal = () => {
  emit('close');
};

if (duration) {
  setTimeout(() => {
    closeModal();
  }, duration);
}

onMounted(() => {
  if (props.smoothAppearance) {
    $anime({
      targets: toast.value,
      translateY: [-40, 0],
      opacity: [0, 1],
      duration: 750,
    });
  }
});
</script>

<style lang="scss" module>
.toast {
  padding: 13px 21px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #434343;
  gap: 25px;

  &.danger {
    background-color: #FBD7DE;

    .close {
      background-color: #FF4D4F;
      color: #fff;
    }
  }

  &.success {
    background-color: #EEF4FF;

    .close {
      background-color: #508FF8;
      color: #fff;
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 8px;
  border: none;
  color: #fff;

  svg {
    stroke: none;
  }
}

.closeIcon {
  width: 11px;
  height: 11px;
}
</style>
