import { defineNuxtPlugin } from '#app';
import { AuthApi } from '~/api/auth';
import { DownloadsApi } from '~/api/downloads';
import { PaymentApi } from '~/api/payment';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      authApi: new AuthApi(),
      downloadsApi: new DownloadsApi(),
      paymentApi: new PaymentApi(),
    },
  };
});