import { uuid } from '@/utils/uuid';

export enum modalType {
  PROMOCODE_MODAL = 'PromocodeModal',
  PAYMENT_BALANCE = 'PaymentBalanceModal',
  REG_SUBSCRB = 'SubsrbRegModal',
}

export type Modal = {
  id: string,
  type: modalType,
  payload?: Record<any, any>,
}

export const useModalStore = defineStore('modal', () => {
  const list = ref<Modal[]>([]);

  const showModal = (modal: Omit<Modal, 'id'>) => {
    list.value.push({
      id: uuid(),
      ...modal,
    });
  };

  const removeModal = (modalId: Modal['id']) => {
    if (!list.value.length) return;

    const modalIndex = list.value.findIndex((element) => element.id === modalId);
    list.value[list.value.length - 1].payload?.onClose?.();
    list.value.splice(modalIndex, 1);
  };

  return {
    list,
    showModal,
    removeModal,
  };
});
