import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { defineNuxtPlugin } from '#app';

export type ApiResponse<R = any> = {
  result: boolean,
  status: number,
  data: R,
  meta?: any,
  headers?: Record<string, any>,
  totalCount?: string | null,
  referalTotalAmount?: string | null,
  teamTotalCount?: string | null,
  message?: string | '',
}

export type RequestProps = {
  method: 'get' | 'post' | 'patch' | 'put' | 'delete',
  path: string,
  payload?: any,
  params?: Record<string, any>
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  
  const api = axios.create({
    baseURL: config.public.apiBaseUrl as string || '',
    timeout: 15000,
  });

  api.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = (nuxtApp.$auth as any).getToken();
    const parsedUrl: string = config.url as string;

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
    }

    config.url = parsedUrl.replace('://', '___').replace(/\/{2,}/g, '/').replace('___', '://')

    return config;
  });

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.code === 'ECONNABORTED') {
        // Обработка таймаута
      }

      if (error.response?.status === 401 || error.response?.status === 440) {
        // Обработка авторизационной ошибки
      }

      return Promise.reject(error);
    }
  );

  const request = async <Response>({
    method,
    path,
    payload,
    params,
  }: RequestProps): Promise<ApiResponse<Response>> => {
    try {
      const config: AxiosRequestConfig = {
        method,
        url: path,
        params,
        data: payload,
      };

      const { status, data, headers } = await api.request<Response>(config);

      return {
        result: true,
        data: data || null,
        status,
        headers: headers || null,
        totalCount: headers['x-total-count'] || null,
        referalTotalAmount: headers['x-total-amount'] || null,
        teamTotalCount: headers['x-team-total-count'] || null,
      };
    } catch (err: any) {
      return {
        result: false,
        status: err.response?.status,
        data: err.response?.data || null,
        message: err.response?.data?.message || '',
      };
    }
  };

  const get = <Response>(path: string, queryParams?: Record<string, any>) =>
    request<Response>({ method: 'get', path, params: queryParams });

  const post = <Response>(path: string, payload?: any, query?: Record<string, any>) =>
    request<Response>({ method: 'post', path, payload, params: query });

  const put = <Response>(path: string, payload?: any, query?: Record<string, any>) =>
    request<Response>({ method: 'put', path, payload, params: query });

  const patch = <Response>(path: string, payload?: any, query?: Record<string, any>) =>
    request<Response>({ method: 'patch', path, payload, params: query });

  const del = <Response>(path: string, payload?: any, query?: Record<string, any>) =>
    request<Response>({
      method: 'delete',
      path,
      payload,
      params: query,
    });

  nuxtApp.provide('api', {
    get,
    post,
    put,
    patch,
    del,
  });
});
