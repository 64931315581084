import { useNuxtApp } from '#app';
import type { CardBalanceResponseDto, CryptoBalanceResponseDto, PlanItemDto, PromoResponseDto } from '~/dto';
import type { ApiResponse } from '~/plugins/axios';

export class PaymentApi {
  private get $api() {
    const nuxtApp = useNuxtApp();
    return nuxtApp.$api;
  };

  async getListOfPlansApi(): Promise<ApiResponse<PlanItemDto[]>> {
    return this.$api.get('/plan');
  };

  async applyPromocode(code: string): Promise<ApiResponse<PromoResponseDto>> {
    return this.$api.post(`/promo/apply/${code}`);
  };

  async cryptoDeposit(amount: number): Promise<ApiResponse<CryptoBalanceResponseDto>> {
    return this.$api.post('/balance', {
      amount,
    });
  };

  async cardDeposit(amount: number): Promise<ApiResponse<CardBalanceResponseDto>> {
    const config = useRuntimeConfig();

    return this.$api.post(`${config.public.stripeBaseUrl}/balance`, {
      amount,
    });
  };

  async stripeCancelSubscrb(): Promise<ApiResponse> {
    const config = useRuntimeConfig();

    return this.$api.del(`${config.public.stripeBaseUrl}/subscription/cancel`);
  };

  async stripeRegSubscrb(planId: string, period: string): Promise<ApiResponse<CardBalanceResponseDto>> {
    const config = useRuntimeConfig();

    return this.$api.post(`${config.public.stripeBaseUrl}/plan/${planId}/subscribe/${period}`);
  };

  async balanceRegSubscrb(planId: string, period: string): Promise<ApiResponse> {
    return this.$api.post(`/plan/${planId}/subscribe/${period}`);
  };
};
