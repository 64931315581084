<template>
  <svg viewBox="0 0 438 440" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M343.786 278.986C343.786 278.744 343.807 278.503 343.807 278.261C343.807 277.348 343.734 276.413 343.608 275.468C341.72 254.308 323.968 237.727 302.345 237.727C279.463 237.727 260.903 256.293 260.903 279.206C260.903 281.012 261.029 282.798 261.26 284.541C261.344 285.454 261.459 286.316 261.585 287.093C261.585 287.093 261.585 287.114 261.585 287.124C269.79 330.505 255.248 353.828 250.632 360.412C220.899 402.9 139.349 413.727 58.543 368.393C58.7318 368.603 58.9312 368.813 59.1305 369.023C59.8334 369.779 60.5259 370.514 61.2288 371.249C62.215 372.247 63.1907 373.234 64.1769 374.232C64.8903 374.778 65.6667 375.345 66.527 375.901C67.9329 376.815 69.2863 377.539 70.5243 378.107C72.098 378.81 73.6717 379.524 75.256 380.228C75.9589 380.858 76.6513 381.488 77.3543 382.118C77.3543 382.538 77.3857 383.168 77.648 383.851C78.7916 386.865 82.9043 387.463 86.5553 388.923C90.5631 390.529 91.8326 392.294 95.4626 394.929C99.6802 397.996 108.262 403.089 127.43 411.931C127.43 411.931 130.903 412.781 134.418 413.527C157.572 418.442 220.868 428.155 276.462 394.772C325.5 365.337 338.289 310.563 339.611 305.9C341.342 301.952 342.307 297.667 342.307 293.183C342.307 292.984 342.318 293.005 342.328 292.984C342.695 289.382 346.892 287.87 347.941 286.19C349.011 284.467 347.186 281.884 343.807 278.975L343.786 278.986Z" fill="url(#paint0_linear_182_2)"/>
    <path d="M161.087 268.379C161.087 261.144 160.08 254.15 158.202 247.524C155.988 239.721 152.925 233.809 151.089 230.606C150.417 229.43 149.882 228.569 149.725 228.328C144.636 220.137 119.1 177.092 112.889 164.932C95.9453 131.769 96.1447 80.4604 119.656 43.3072C130.043 26.9043 142.905 16.5186 151.645 10.5645C137.229 15.1745 117.453 23.1344 96.4069 37.164C66.4851 57.1162 48.8489 79.4628 42.1343 88.5673C38.0007 94.1749 8.06835 135.697 1.41672 194.293C-1.01731 215.737 0.346587 233.494 1.02854 240.562C1.90982 249.719 3.34716 263.853 9.05455 281.432C12.9469 293.435 15.2131 300.555 20.868 309.05C24.3932 314.343 41.1796 338.17 71.3217 343.589C79.5681 345.069 85.9784 344.607 87.2898 344.576C128.259 343.316 161.087 309.712 161.087 268.4V268.379Z" fill="url(#paint1_linear_182_2)"/>
    <path d="M408.267 107.995C377.8 55.8458 333.61 31.0735 314.861 21.99C280.879 5.53464 250.192 1.77521 236.385 0.683089C226.638 -0.0834977 218.077 -0.0939989 211.195 0.168531C211.006 0.168531 210.828 0.15803 210.649 0.15803C177.485 0.15803 150.596 27.0726 150.596 60.2669C150.596 93.4611 177.485 120.376 210.649 120.376C211.058 120.376 211.468 120.355 211.877 120.344C211.982 120.344 212.076 120.355 212.17 120.365C262.131 121.321 281.593 106.378 308.881 104.466C337.576 102.461 379.521 114.527 434.706 180.926C428.946 147.511 416.944 122.843 408.278 108.005L408.267 107.995Z" fill="url(#paint2_linear_182_2)"/>
    <path d="M436.909 240.299C436.909 240.005 436.909 239.732 436.909 239.732C436.909 231.846 434.706 224.485 430.876 218.215C423.217 205.12 409.022 196.32 392.771 196.32C369.039 196.32 349.693 215.065 348.675 238.577C348.413 256.314 345.297 317.042 299.869 361.294C243.959 415.753 146.556 423.241 63.6836 373.727C75.56 385.709 94.9798 402.648 122.604 416.394C137.47 423.787 189.687 448.832 257.399 436.756C257.399 436.756 317.62 426.023 364.077 383.84C385.731 364.182 402.832 338.506 402.832 338.506C414.289 321.316 419.829 308.221 421.277 304.714C424.12 297.846 426.019 291.482 429.806 278.765C429.806 278.765 436.657 255.82 436.898 240.289L436.909 240.299Z" fill="url(#paint3_linear_182_2)"/>
    <defs>
    <linearGradient id="paint0_linear_182_2" x1="58.5323" y1="328.223" x2="348.236" y2="328.223" gradientUnits="userSpaceOnUse">
    <stop offset="0.49" stop-color="#F6F6F6"/>
    <stop offset="1" stop-color="#DDDDDD"/>
    </linearGradient>
    <linearGradient id="paint1_linear_182_2" x1="0.0213962" y1="177.614" x2="161.081" y2="177.614" gradientUnits="userSpaceOnUse">
    <stop offset="0.49" stop-color="#F6F6F6"/>
    <stop offset="1" stop-color="#DDDDDD"/>
    </linearGradient>
    <linearGradient id="paint2_linear_182_2" x1="150.585" y1="90.4683" x2="434.696" y2="90.4683" gradientUnits="userSpaceOnUse">
    <stop offset="0.49" stop-color="#F6F6F6"/>
    <stop offset="1" stop-color="#DDDDDD"/>
    </linearGradient>
    <linearGradient id="paint3_linear_182_2" x1="63.6698" y1="318.156" x2="436.895" y2="318.156" gradientUnits="userSpaceOnUse">
    <stop offset="0.49" stop-color="#F6F6F6"/>
    <stop offset="1" stop-color="#DDDDDD"/>
    </linearGradient>
    </defs>
  </svg>
</template>