import type { UserSubscriptionDto } from '~/dto';

export const useSubsrbStore = defineStore('subsrb', () => {
  const subscrb = ref<UserSubscriptionDto | null>(null);

  const patchSubscrb = (newSubsrb: UserSubscriptionDto | null) => {
    subscrb.value = newSubsrb;
  };

  return {
    subscrb,
    patchSubscrb,
  }
});
