<template>
  <UiModal
    :label="t('modals.subscription.title')"
    @close="emits('close')"
  >
    <form
      @submit="onSubmit"
      :class="$style.form"
    >
      <UiSelect
        :label="t('modals.subscription.method')"
        :placeholder="t('modals.subscription.method')"
        name="method"
        :options="paymentOptions"
      />

      <div :class="$style.params">
        <div
          v-for="({ label, value }) of paymentParams"
          :key="label"
          :class="$style.paramsItem"
        >
          {{ label }} <div :class="$style.paramsItemValue">{{ value }}</div>
        </div>
      </div>

      <div :class="$style.total">
        {{ t('modals.subscription.total') }}

        <div :class="$style.totalValue">
          <template v-if="+price > 0">
            ${{ price }}
          </template>
          <template v-else>
            {{ t('modals.subscription.free') }}
          </template>
        </div>
      </div>

      <UiButton
        wide
        :loading="loader"
      >
        <template #label>
          {{ t('modals.subscription.button') }}
        </template>
      </UiButton>
    </form>
  </UiModal>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { Decimal } from 'decimal.js';
import type { SelectOption } from '@/components/ui/Select';
import type { SubscrbRegParams } from './index.js';

interface PaymentParams {
  label: string,
  value: string,
}

const { t } = useI18n();

const props = defineProps<SubscrbRegParams>();
const emits = defineEmits(['close']);

const { $localePath } = useNuxtApp();
const { $moment } = useNuxtApp();
const { $mixpanel } = useNuxtApp();

const {
  fetchUser,
} = useAuthStore();
const toastStore = useToastStore();
const modalsStore = useModalStore();

const paymentOptions = computed<SelectOption[]>(() => {
  const response = [
    {
      label: t('modals.subscription.payment.balance'),
      value: 'balance',
      icon: '/images/balance.svg',
    }, {
      label: t('modals.subscription.payment.card'),
      value: 'card',
      icon: '/images/card.svg',
    },
  ];

  if (props.plan.name === 'Free') {
    return response.filter(option => option.value !== 'card');
  }

  return response;
});

const heldAmount = ref<number>();

const price = computed<string>(() => {
  const timeLineValue = props.timeline || '1';
  let preventPrice = props.plan.finalPrices
    ? String(props.plan.finalPrices[timeLineValue])
    : String(props.plan.prices[timeLineValue]);

  if (formData.method === 'card' && heldAmount.value) {
    preventPrice = new Decimal(preventPrice).plus(heldAmount.value).toString();
  }

  return preventPrice;
});

const pricePerMonth = computed<string>(() => {
  const timeLineValue = props.timeline || '1';

  return new Decimal(price.value).div(Number(timeLineValue)).toFixed(2).toString();
});

const paymentParams = computed<PaymentParams[]>(() => {
  const response = [
    {
      label: t('modals.subscription.params.user'),
      value: props.userEmail,
    }, {
      label: t('modals.subscription.params.subcription'),
      value: props.plan.name,
    }, {
      label: t('modals.subscription.params.period'),
      value: props.plan.name === 'Free' ? t('modals.subscription.week') : props.timeline + t('modals.subscription.params.price'),
    },
  ];

  if (heldAmount.value) {
    response.push({
      label: props.activePaymentSystem === 'stripe' ? t('modals.subscription.params.chargeback') : t('modals.subscription.params.cashback'),
      value: '$' + Math.abs(+heldAmount.value),
    });
  }

  response.push( {
    label: t('modals.subscription.params.month'),
    value: +pricePerMonth.value > 0 ? '$' + pricePerMonth.value : t('modals.subscription.free'),
  });

  return response;
});

const schema: yup.AnyObject = yup.object({
  method: yup.string().required(t('errors.paymentMethod')),
});

const loader = ref<boolean>(false);
const { values: formData, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    method: 'balance',
  },
});

const preventBalanceMethod = async (): Promise<void> => {
  try {
    const authApi = useNuxtApp().$authApi;

    const { result, data } = await authApi.getUserBalance();

    if (result) {
      const difference = new Decimal(price.value)
        .minus(data.amount)
        .absoluteValue();

      if (difference) {
        emits('close');
        navigateTo($localePath('/account'));

        modalsStore.showModal({
          type: modalType.PAYMENT_BALANCE,
          payload: {
            amount: difference,
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const regFromBalance = async (planId: string, period: string): Promise<void> => {
  try {
    const paymentApi = useNuxtApp().$paymentApi;

    const { result, status } = await paymentApi.balanceRegSubscrb(planId, period);

    if (result) {
      dataLayer.push({ event: 'buytarif' });
      $mixpanel.track('pick_tariff', {
        email: props.userEmail,
        user_id: props.userId,
        plan_type: props.plan.name,
        cost: price.value,
        utmSource: localStorage.getItem('utmSource'),
        utmMedium: localStorage.getItem('utmMedium'),
        utmCampaign: localStorage.getItem('utmCampaign'),
        utmContent: localStorage.getItem('utmContent'),
        utmTerm: localStorage.getItem('utmTerm'),
        refId: localStorage.getItem('refId'),
      });
      await fetchUser();
      navigateTo($localePath('/account'));
    }

    if (status === 402) {
      toastStore.showDanger({
        text: 'Insufficient funds on the balance sheet',
        duration: 3000,
      });

      await preventBalanceMethod();
    }
  } catch (error) {
    console.error(error);
  }
};

const checkingTypeOfCurrentSubscrb = async (): Promise<boolean> => {
  try {
    if (props.activePaymentSystem === 'stripe') {
      const paymentApi = useNuxtApp().$paymentApi;

      const { result } = await paymentApi.stripeCancelSubscrb();

      return result;
    }

    return true;
  } catch (error) {
    console.error(error);
    toastStore.showDanger({
      text: 'The current subscription could not be cancelled. Try again or contact support',
      duration: 3000,
    });

    return false;
  }
};

const regByCard = async (planId: string, period: string): Promise<void> => {
  try {
    const checkerCurrentSubscrb = await checkingTypeOfCurrentSubscrb();

    if (!checkerCurrentSubscrb) return;

    const paymentApi = useNuxtApp().$paymentApi;

    const { result, data } = await paymentApi.stripeRegSubscrb(planId, period);

    if (result) {
      dataLayer.push({ event: 'buytarif' });
      $mixpanel.track('pick_tariff', {
        email: props.userEmail,
        user_id: props.userId,
        plan_type: props.plan.name,
        cost: price.value,
        utmSource: localStorage.getItem('utmSource'),
        utmMedium: localStorage.getItem('utmMedium'),
        utmCampaign: localStorage.getItem('utmCampaign'),
        utmContent: localStorage.getItem('utmContent'),
        utmTerm: localStorage.getItem('utmTerm'),
        refId: localStorage.getItem('refId'),
      });
      window.open(data.url, '_blank');
      navigateTo($localePath('/account'));
      emits('close');
    } else throw new Error('Error @stripeRegSubscrb');
  } catch (error) {
    console.error(error);
    toastStore.showDanger({
      text: 'The link to the payment could not be generated. Try again or contact support',
      duration: 3000,
    });
  }
};

const onSubmit = handleSubmit(
  async (values): Promise<void> => {
    try {
      loader.value = true;
      const timeLineValue = props.timeline || '1';

      if (values.method === 'balance') {
        await regFromBalance(props.plan._id, timeLineValue);
      } else {
        await regByCard(props.plan._id, timeLineValue)
      }
      emits('close')
    } catch (error) {
      console.error(error);
    } finally {
      loader.value = false;
    }
  }
);

const getBalance = async (): Promise<void> => {
  try {
    const authApi = useNuxtApp().$authApi;

    const { result, data } = await authApi.getUserBalance();

    if (result) {
      heldAmount.value = data.heldAmount;
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  await getBalance();
})
</script>


<style lang="scss" module>
  .form {
    > * {
      width: 100%;
    }
  }

  .params {
    display: flex;
    flex-direction: column;
    margin: 4px 0 22px 0;

    &Item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding: 18px 0;
      color: #71787F;

      &Value {
        font-weight: 400;
        color: #121E29;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #F0F0F0;
      }

      &:last-child {
        border-bottom: 1px dashed #71787F;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 20px 24px;
    border-radius: 15px;
    background: #FBFCFF;
    color: #71787F;
    margin-bottom: 16px;

    &Value {
      color: #293EFF;
      font-weight: 400;
    }
  }
</style>