<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <mask id="path-1-inside-1_137_383" fill="currentColor">
      <path d="M10.5 0.968282C10.5 0.433515 10.0656 -0.00463339 9.53307 0.0446106C7.83656 0.201498 6.19848 0.769493 4.76337 1.70562C3.05711 2.81862 1.71147 4.40394 0.890463 6.26836C0.0694522 8.13277 -0.191471 10.1958 0.139497 12.2059C0.470464 14.216 1.37903 16.0864 2.75445 17.5892C4.12987 19.0919 5.91275 20.1622 7.88577 20.6694C9.8588 21.1766 11.9368 21.0989 13.8664 20.4457C15.796 19.7926 17.494 18.5922 18.7534 16.9909C19.8126 15.6441 20.523 14.0626 20.8291 12.3866C20.9252 11.8605 20.5271 11.3891 19.9945 11.3418C19.4618 11.2946 18.9972 11.69 18.8902 12.2139C18.6242 13.5157 18.058 14.7424 17.2312 15.7938C16.2041 17.0997 14.8193 18.0787 13.2455 18.6114C11.6718 19.1441 9.97706 19.2074 8.36793 18.7938C6.7588 18.3801 5.30474 17.5073 4.183 16.2817C3.06125 15.0561 2.32026 13.5306 2.05033 11.8912C1.78041 10.2519 1.99321 8.56937 2.6628 7.04882C3.33238 5.52827 4.42984 4.23534 5.82141 3.32761C6.94166 2.59686 8.21356 2.14116 9.53375 1.99125C10.0651 1.93091 10.5 1.50305 10.5 0.968282Z"/>
    </mask>
    <path d="M10.5 0.968282C10.5 0.433515 10.0656 -0.00463339 9.53307 0.0446106C7.83656 0.201498 6.19848 0.769493 4.76337 1.70562C3.05711 2.81862 1.71147 4.40394 0.890463 6.26836C0.0694522 8.13277 -0.191471 10.1958 0.139497 12.2059C0.470464 14.216 1.37903 16.0864 2.75445 17.5892C4.12987 19.0919 5.91275 20.1622 7.88577 20.6694C9.8588 21.1766 11.9368 21.0989 13.8664 20.4457C15.796 19.7926 17.494 18.5922 18.7534 16.9909C19.8126 15.6441 20.523 14.0626 20.8291 12.3866C20.9252 11.8605 20.5271 11.3891 19.9945 11.3418C19.4618 11.2946 18.9972 11.69 18.8902 12.2139C18.6242 13.5157 18.058 14.7424 17.2312 15.7938C16.2041 17.0997 14.8193 18.0787 13.2455 18.6114C11.6718 19.1441 9.97706 19.2074 8.36793 18.7938C6.7588 18.3801 5.30474 17.5073 4.183 16.2817C3.06125 15.0561 2.32026 13.5306 2.05033 11.8912C1.78041 10.2519 1.99321 8.56937 2.6628 7.04882C3.33238 5.52827 4.42984 4.23534 5.82141 3.32761C6.94166 2.59686 8.21356 2.14116 9.53375 1.99125C10.0651 1.93091 10.5 1.50305 10.5 0.968282Z" stroke="url(#paint0_angular_137_383)" stroke-width="4" mask="url(#path-1-inside-1_137_383)"/>
    <defs>
      <radialGradient id="paint0_angular_137_383" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5 10.5) rotate(-95.4403) scale(10.5475)">
        <stop offset="0.284201" stop-color="currentColor" stop-opacity="0"/>
        <stop offset="1" stop-color="currentColor"/>
      </radialGradient>
    </defs>
  </svg>
</template>