<template>
  <UiModal
    :label="t('modals.promo.title')"
    @close="emits('close')"
  >
    <form
      @submit="onSubmit"
      :class="$style.form"
    >
      <UiField
        type="text"
        :label="t('modals.promo.input')"
        name="promocode"
        :placeholder="t('modals.promo.input')"
        :rounded="false"
      />

      <UiButton
        wide
        :loading="loader"
      >
        <template #label>
          {{ t('modals.promo.button') }}
        </template>
      </UiButton>
    </form>
  </UiModal>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import type { PlanItemDto } from '~/dto';

const { t } = useI18n();

const emits = defineEmits(['close']);

const toastStore = useToastStore();
const authStore = useAuthStore();

const { $mixpanel } = useNuxtApp();

const schema: yup.AnyObject = yup.object({
  promocode: yup.string().required(t('errors.schema.code')),
});

const loader = ref<boolean>(false);
const { handleSubmit } = useForm({
  validationSchema: schema,
});

const getPlanNameFromId = async (
    _id: string,
  ): Promise<string | void> => {
  const paymentApi = useNuxtApp().$paymentApi;
  const { result, data } = await paymentApi.getListOfPlansApi();

  if (result) {
    const plan = data.find((plan: PlanItemDto) => plan._id === _id);

    return plan?.name;
  }
};

const successMessage = async ({
  type,
  value,
  valueType,
  plan,
}: {
  type: string,
  value?: number,
  valueType?: string,
  plan?: string,
}) => {
  let nameOfPlan;

  if (plan
      && (type === 'promotedSubscription') || type === 'makeDiscount') {
    nameOfPlan = await getPlanNameFromId(plan as string);

    if (!nameOfPlan && type !== 'makeDiscount') return t('errors.promocode.success');
  }

  switch (type) {
    case 'promotedBalance': {
      return t('errors.promocode.promotedBalance', { value });
    }
    case 'promotedSubscription': {
      return t('errors.promocode.promotedSubscription', { plan: nameOfPlan });
    }
    case 'makeDiscount': {
      return nameOfPlan
        ? t(
          'errors.promocode.makeDiscount',
          {
            plan: nameOfPlan,
            value,
            type: valueType === 'percent' ? '%' : '$',
          },
        )
        : t(
          'errors.promocode.makeTotalDiscount',
          {
            value,
            type: valueType === 'percent' ? '%' : '$',
          },
        );
    }
    case 'extendExpiration': {
      if (valueType) {
        const key = valueType[0].toUpperCase() + valueType.slice(1);

        return t(
          `errors.promocode.extendExpiration${key}`,
          {
            value,
          },
        );
      }

      return t('errors.promocode.success');
    }
    default: return t('errors.promocode.success');
  }
};

const onSubmit = handleSubmit(
  async (values): Promise<void> => {
    try {
      loader.value = true;
      const paymentApi = useNuxtApp().$paymentApi;

      const { result, data } = await paymentApi.applyPromocode(values.promocode);

      if (result && data.promo) {
        const successText = await successMessage(data.promo);

        if (data.promo.type !== 'makeDiscount') {
          await authStore.fetchUser();
        }

        toastStore.showSuccess({
          text: successText,
          duration: 3000,
        });

        emits('close');
      } else throw Error('Error @applyPromocode');
    } catch (error) {
      console.error(error);
      toastStore.showDanger({
        text: t('errors.promocode.fail'),
        duration: 3000,
      });
    } finally {
      loader.value = false;
    }
  }
);
</script>

<style lang="scss" module>
  .form {

    label {
      width: 100%;
    }
  }
</style>