<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <ClientOnly>
    <UiToastLayer :class="$style.toastLayer" />
    <UiModalLayer :class="$style.modalLayer" />
  </ClientOnly>
</template>

<script setup lang="ts">
useHead({
  link: [{ rel: 'stylesheet', href: 'https://unpkg.com/aos@2.3.1/dist/aos.css' }],
  script: [{ src: '/js/aos.js' }],
})

if (import.meta.client) {
  onMounted(() => {
    window?.visualViewport?.addEventListener('resize', getVisualViewportHeight);
    window.addEventListener('orientationchange', getVisualViewportHeight);
    getVisualViewportHeight();

    AOS.init({
      once: true,
    });
  });

  function getVisualViewportHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--visual-viewport-height', `${window?.visualViewport?.height}px`);
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Gilroy';
  src: url('@/assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@import url('~/assets/styles/styles.scss');

html {
  font-family: 'Gilroy';
  font-weight: 300;
  color: #596269;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 1.4;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: normal;
  }
}

@import url('swiper/swiper-bundle.css');

.cs .swiper-horizontal > .swiper-scrollbar,
.cs .swiper-scrollbar.swiper-scrollbar-horizontal {
  max-width: 694px;
  width: 100%;
  left: 50%;
  height: 4px;
  transform: translateX(-50%);
  border-radius: 2px;
  background: #D9D9D9;

  @media (max-width: 1023px) {
    max-width: 454px;
  }
}

.cs .swiper-scrollbar-drag {
  height: 4px;
  border-radius: 2px;
  background: #293EFF;
}
</style>

<style lang="scss" module>
.toastLayer {
  position: fixed;
  inset: 0;
  z-index: 100;
}

.modalLayer {
  position: fixed;
  inset: 0;
  z-index: 99;
}
</style>