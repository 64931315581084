import type { AnimeInstance, AnimeParams } from 'animejs';

export const playAnimation = (
  params: Omit<AnimeParams, 'complete' | 'autoplay'>,
) => new Promise((resolve) => {
  const { $anime } = useNuxtApp();
  $anime({
    ...params,
    autoplay: true,
    complete: resolve,
  });
});

export const createAnimation = (
  params: Omit<AnimeParams, 'complete' | 'autoplay'>,
) => {
  const { $anime } = useNuxtApp();
  let animation: AnimeInstance | null = null;

  const init = () => {
    animation = $anime({
      ...params,
      autoplay: false,
    });
    return animation;
  };

  const play = () => new Promise((resolve) => {
    if (!animation) {
      resolve(false);
      return;
    }
    animation.complete = resolve;

    animation.play();
  });

  return {
    animation,
    init,
    play,
  };
};
