<template>
  <div :class="$style.loader">
    <UiIconsSpinner />
  </div>
</template>

<style lang="scss" module>
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>