import type { DownloadResponseDto, PlatformsTypes } from '~/dto';
import type { ApiResponse } from '~/plugins/axios';

export class DownloadsApi {
  private get $api() {
    const nuxtApp = useNuxtApp();
    return nuxtApp.$api;
  };

  async getDownloadOptApi(platform: PlatformsTypes): Promise<ApiResponse<DownloadResponseDto>> {
    return this.$api.get(`/dashboard/${platform}/latest`);
  };
};
