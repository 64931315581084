<template>
  <div :class="$style.toastLayer">
    <div
      v-for="toast in toasts"
      :id="`toast-${toast.id}`"
      :key="toast.id"
      :class="$style.toastWrapper"
    >
      <Toast
        :class="$style.toast"
        :toast="toast"
        :smooth-appearance="true"
        @close="hideToast(toast.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
} from 'vue';
import { useToastStore } from '@/stores/toasts';
import Toast from '@/components/ui/Toast/Toast.vue';
import { type Toast as IToast } from '@/components/ui/Toast/index.js';
import { playAnimation } from '@/utils/animation';

const toastStore = useToastStore();

const toasts = computed(() => toastStore.list);
const hideToast = async (toastId: IToast['id']) => {
  const currentToast = document.getElementById(`toast-${toastId}`);

  if (!currentToast) return;

  currentToast.style.overflow = 'hidden';

  await playAnimation({
    targets: currentToast,
    height: 0,
    marginTop: 0,
    opacity: [1, 0],
    easing: 'easeInOutQuart',
    duration: 400,
  });

  toastStore.removeToast(toastId);
};
</script>

<style lang="scss" module>
.toastLayer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  pointer-events: none;
  * {
    pointer-events: all;
  }
}

.toastWrapper {
  width: 100%;
  max-width: 620px;
  margin-top: 24px;
  display: flex;
  border-radius: 5px;
}

.toast {
  width: 100%;
}
</style>
