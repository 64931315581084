<template>
  <component
    :is="to ? link : button"
    :to="localePath(to)"
    :target="blank ? '_blank' : ''"
    :class="[
      $style.button,
      wide && $style.wide,
      disabled && $style.disabled,
      $slots.preppendIcon && $style.withPreppendIcon,
      $style[type || 'default'],
      $style[color ? `color-${color}` : 'color-default'],
      loading && $style.loading,
    ]"
  >
    <span
      v-if="$slots.label"
      :class="$style.label"
    >
      <slot name="label" />
    </span>

    <span
      v-if="$slots.preppendIcon"
      :class="$style.preppendIcon"
    >
      <slot name="preppendIcon" />
    </span>
    <span
      v-if="type === 'default'"
      :class="[$style.preppendIcon, $style.loader]"
    >
      <UiIconsSpinner />
    </span>
  </component>
</template>

<script setup lang="ts">
import { type ButtonParams } from './index';

const props = withDefaults(
  defineProps<ButtonParams>(), {
    type: 'default',
  },
);
const link = resolveComponent('nuxt-link');
const button = resolveComponent('button');
</script>

<style lang="scss" module>
  .button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: none;
    box-shadow: none;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    transition: 200ms ease;
    line-height: normal;

    &.disabled {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.8;
    }

    &.loading {
      user-select: none;
      pointer-events: none;
      cursor: default;
    }

    &.wide {
      width: 100%;
    }

    .label {
      text-align: center;
      -webkit-line-box-contain: glyphs;
      padding-top: 2px;
    }

    &:not(.withPreppendIcon) {
      justify-content: center;

    }

    &:not(.circle):not(.icon):not(.small):not(.smallest) {

      .label {
        width: 100%;
        transition: 300ms ease-in-out;
      }

      &.withPreppendIcon {
        padding: 3px 3px 3px 0;

        .label {
          padding-left: 60px;
          padding-right: 30px;
          transition: 300ms ease-in-out;
          transform-origin: center;
        }
      }

      .preppendIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 44px;
        height: 44px;
        margin-left: auto;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 44px;
        transition: 300ms ease-in-out;
      }

      .loader {
        width: 0;
        height: 0;
        margin-left: 0;
        opacity: 0;
        justify-self: flex-end;
        transition: 300ms ease-in-out;
        transform: scale(0);
        transform-origin: center;

        svg {
          animation: spinner 1500ms ease;
          animation-iteration-count: infinite;
        }
      }

      svg {
        max-width: 100%;
        max-height: 100%;
        fill: #fff;
        color: #fff;
      }

      &.loading {
        padding: 3px 3px 3px 0;

        .label {
          padding-left: 60px;
          padding-right: 30px;
          transition: 300ms ease-in-out;
          transform-origin: center;
        }

        .preppendIcon:not(.loader) {
          transform: scale(0);
          width: 0;
          height: 0;
        }

        .loader {
          opacity: 1;
          transform: scale(1);
          width: 44px;
          height: 44px;
        }
      }
    }

    &.smallest {
      &.withPreppendIcon {
        padding-right: 12px;

        .label {
          padding-left: 24px;
          padding-right: 18px;
        }
      }

      .preppendIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-left: auto;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        fill: #fff;
        color: #fff;
      }
    }
  }

  .default {
    min-width: 218px;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
    line-height: 1;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .small {
    min-width: 114px;
    height: 43px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 21.5px;
    font-size: 14px;
  }

  .smallest {
    min-width: 124px;
    height: 32px;
    border-radius: 16px;
    font-size: 12px;
  }

  .circle {
    font-size: 14px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 150px;
    height: 150px;
    border-radius: 150px;

    @media (max-width: 1023px) {
      width: 110px;
      height: 110px;
      font-size: 12px;
      gap: 10px;
    }

    .preppendIcon {
      width: 12px;
      height: 12px;

      @media (max-width: 1023px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8px;
        height: 8px;
      }
    }
  }

  .icon {
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    aspect-ratio: 1;

    .preppendIcon {
      width: 16px;
      height: 16px;
    }
  }

  .color {
    &-default {
      background: #293EFF;

      &:hover {
        background: #3246FF;
      }

      &:active {
        background: #4457FF;
      }
    }

    &-outline {
      background: transparent;
      border: 1px solid #4457FF;
      color: #4457FF;

      &:hover {
        border-color: #3246FF;
        color: #3246FF;
      }

      &:active {
        border-color: #4457FF;
        color: #4457FF;
      }
    }

    &-transparent {
      background-color: rgba(255, 255, 255, 0.30);
      backdrop-filter: blur(7.5px);
      color: #fff;

      &:hover {
        background: rgba(255, 255, 255, 0.50);
      }

      &:active {
        background: rgba(255, 255, 255, 0.80);
      }
    }

    &-gray {
      background: #39415B;

      &:hover {
        background: #4D5672;
      }

      &:active {
        background: #5E6782;
      }
    }

    &-red {
      background: #FF295C;

      &:hover {
        background: #FF3D6C;
      }

      &:active {
        background: #FF3B6A;
      }
    }

    &-white {
      background-color: #fff;
      color: #121E29;

      &:hover {
        background: rgba(255, 255, 255, .9);
      }

      &:active {
        background: rgba(255, 255, 255, .8);
      }
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>