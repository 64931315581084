<template>
  <UiModal
    :label="t('modals.deposit.title')"
    @close="emits('close')"
  >
    <form
      @submit="onSubmit"
      :class="$style.form"
    >
      <UiSelect
        :label="t('modals.deposit.method')"
        :placeholder="t('modals.deposit.method')"
        name="method"
        :options="paymentOptions"
      />

      <UiAmountField
        :label="t('modals.deposit.amount')"
        name="amount"
        :amount="amount"
      />

      <UiButton
        wide
        :loading="loader"
      >
        <template #label>
          {{ t('modals.deposit.button') }}
        </template>
      </UiButton>
    </form>
  </UiModal>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import type { SelectOption } from '@/components/ui/Select';
import type { PaymentBalanceParams } from './index.js';

const { t } = useI18n();

const emits = defineEmits(['close']);
defineProps<PaymentBalanceParams>();

const { $mixpanel } = useNuxtApp();

const paymentOptions = computed<SelectOption[]>(() => [
  {
    label: t('modals.deposit.options.crypto'),
    value: 'crypto',
    icon: '/images/crypto.svg',
  }, {
    label: t('modals.deposit.options.card'),
    value: 'card',
    icon: '/images/card.svg',
  },
]);

const toastStore = useToastStore();

const schema: yup.AnyObject = yup.object({
  method: yup.string().required(t('errors.paymentMethod')),
  amount: yup.number().min(0.01).required(),
});

const loader = ref<boolean>(false);
const { handleSubmit } = useForm({
  validationSchema: schema,
});

const paymentByCard = async (amount: number): Promise<string> => {
  try {
    const paymentApi = useNuxtApp().$paymentApi;

    const { result, data } = await paymentApi.cardDeposit(amount);

    if (result) {
      return data.url;
    } else throw new Error('Error @cardDeposit');
  } catch (error) {
    console.error(error);

    return '';
  }
};

const paymentByCrypto = async (amount: number): Promise<string> => {
  try {
    const paymentApi = useNuxtApp().$paymentApi;

    const { result, data } = await paymentApi.cryptoDeposit(amount);

    if (result) {
      return data.paymentUrl;
    } else throw new Error('Error @cardDeposit');
  } catch (error) {
    console.error(error);

    return '';
  }
};

const onSubmit = handleSubmit(
  async (values): Promise<void> => {
    try {
      loader.value = true;
      let url: string = '';

      if (values.method === 'crypto') {
        url = await paymentByCrypto(values.amount);
      } else {
        url = await paymentByCard(values.amount);
      }

      window.open(url, '_blank');
      emits('close');
    } catch (error) {
      console.error(error);
      toastStore.showDanger({
        text: 'The payment could not be generated. Try again later or contact support',
        duration: 3000,
      });
    } finally {
      loader.value = false;
    }
  }
);
</script>

<style lang="scss" module>
  .form {

    > * {
      width: 100%;
    }
  }
</style>