export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    const utmParams = to.query;

    if (utmParams.utm_source) localStorage.setItem('utmSource', utmParams.utm_source || '');
    if (utmParams.utm_medium) localStorage.setItem('utmMedium', utmParams.utm_medium || '');
    if (utmParams.utm_campaign) localStorage.setItem('utmCampaign', utmParams.utm_campaign || '');
    if (utmParams.utm_content) localStorage.setItem('utmContent', utmParams.utm_content || '');
    if (utmParams.utm_term) localStorage.setItem('utmTerm', utmParams.utm_term || '');
    if (utmParams.fbclid) localStorage.setItem('fbclid', utmParams.fbclid || '');
    if (utmParams.gclid) localStorage.setItem('gclid', utmParams.gclid || '');
    if (utmParams.refId) localStorage.setItem('refId', utmParams.refId || '');
  }
});
