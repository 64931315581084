export default defineNuxtPlugin((nuxtApp) => {
  const setToken = (token: string) => {
    const options = {
      expires: new Date('2050-01-01'),
      httpOnly: false,
    };

    useCookie('token', options).value = token;
  };

  const getToken = () => {
    return useCookie('token').value;
  };

  const clearToken = () => {
    useCookie('token').value = null;
  };

  nuxtApp.provide('auth', {
    setToken,
    getToken,
    clearToken,
  });
});