export default defineNuxtRouteMiddleware(async (to) => {
  const { $auth, $localePath } = useNuxtApp();
  const pathName: string = to.name as string;
  const isAuthView: boolean = pathName?.includes('auth');
  const isAuthRequired: boolean = to.meta.authRequired as boolean || false;

  const token = $auth.getToken();
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  if (token) {

    if (!user.value) {
      await authStore.fetchUser();
    }

    if (user.value) {
      if (isAuthView) {
        return navigateTo($localePath('/account'));
      }
    }
  } else if (isAuthRequired) {
    if (!isAuthView) {
      return navigateTo('/auth/signin');
    }
  }
});