<template>
  <div
    ref="selectWrapper"
    :class="$style.fieldGroup"
  >
    <div
      v-if="label || $slots.label"
      @click="toggleDropdown"
      :class="$style.label"
    >
      <slot v-if="$slots.label" name="label" />
      <template v-else>
        {{ label }}
      </template>
    </div>

    <div :class="$style.select">
      <div
        :class="[
          $style.selectField,
          errorMessage && $style.hasError,
        ]"
        @click="toggleDropdown"
      >
        <div
          v-if="!selectedOption"
          :class="$style.placeholder"
        >
          {{ placeholder }}
        </div>
        <div
          v-else
          :class="$style.selected"
        >
          <img v-if="selectedOption.icon" :src="selectedOption.icon" alt=""/>
          {{ selectedOption.label }}
        </div>

        <div :class="[$style.arrow, isOpen && $style.open]">
          <UiIconsSelectArrow />
        </div>
      </div>

      <div :class="[$style.dropDown, isOpen && $style.open]">
        <div
          v-for="option of options"
          :key="option.value"
          :class="$style.option"
          @click="selectOption(option.value)"
        >
          <img v-if="option.icon" :src="option.icon" alt=""/>
          {{ option.label}}
          <div :class="[$style.optionLight, option.value === selectedOption?.value && $style.active]" />
        </div>
      </div>
      <span
      v-if="errorMessage && !help"
      :class="$style.error"
    >
      {{ errorMessage }}
    </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import type { SelectOption, SelectParams } from './index.js';

const props = defineProps<SelectParams>();

const isOpen = ref<boolean>(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const {
  value,
  errorMessage,
  handleChange,
  handleBlur,
} = useField(() => props.name);

const selectedOption = computed<SelectOption | null>(() => {
  return props.options.find((option: SelectOption) => option.value === value.value) || null;
});

const selectOption = (selected: SelectOption['value']) => {
  value.value = selected;
  isOpen.value = false;
};

const selectWrapper = ref<HTMLDivElement | null>(null);

const handleClickOutside = (event: MouseEvent): void => {
  if (selectWrapper.value && !selectWrapper.value.contains(event.target as Node)) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" module>
  .fieldGroup {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .error {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: right;
      font-size: 12px;
      color: #FF3C77;
    }
  }

  .label {
    margin-bottom: 10px;
    color: #596269;
    cursor: pointer;

    > span {
      color: #71787F;
    }
  }

  .select {
    position: relative;
    width: 100%;

    &Field {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 15px 20px 15px 29px;
      background: #F4F4F4;
      border: 1px solid #F4F4F4;
      width: 100%;
      height: 50px;
      cursor: pointer;

      &.rounded {
        border-radius: 25px;
      }

      &:not(.rounded) {
        border-radius: 14px;
      }

      &.hasError {
        border-color: #FF3C77;
      }
    }
  }

  .placeholder {
    flex: 1;
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    background-color: transparent;
    color: #85868E;
  }

  .selected {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 9px;
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    background-color: transparent;
    color: #121E29;

    img {
      flex-shrink: 0;
      width: auto;
      max-width: 20px;
      height: 20px;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 17px;
    height: 17px;
    background-color: #D9D9D9;
    transition: 200ms ease;
    color: #8D9398;
    border-radius: 17px;

    &.open {
      transform: rotate(180deg);
    }

    svg {
      width: 5px;
      height: auto;
    }
  }

  .dropDown {
    z-index: 2;
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    width: 100%;
    border-radius: 0 0 14px 14px;
    background: #F4F4F4;
    color: #121E29;
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 17.5px 0 6.5px 0;
    transition: 100ms ease;

    &:not(.open) {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-20px);
    }
  }

  .option {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 7.5px 27px 7.5px 29px;
    cursor: pointer;

    img {
      flex-shrink: 0;
      width: auto;
      max-width: 20px;
      height: 20px;
    }

    &Light {
      flex-shrink: 0;
      border-radius: 6px;
      width: 6px;
      height: 6px;
      margin-left: auto;
      background-color: #D9D9D9;
      opacity: 0;
      transition: 200ms ease;

      &.active {
        opacity: 1;
        background-color: #0019FF;
      }
    }

    &:hover {

      .optionLight {
        opacity: 1;
      }
    }
  }
</style>