<template>
  <div :class="$style.systemLayout">
    <img
      src="/images/partBcg.svg" alt=""
      :class="$style.background"
    >

    <UiIconsLogoLarge :class="$style.logo" />

    <div :class="$style.main">
      <div
        v-if="$slots.status"
        :class="$style.status"
      >
        <slot name="status" />
      </div>

      <div :class="$style.content">
        <slot />
      </div>

      <UiButton :to="localePath('/')">
        <template #label>
          {{ t('errors.button') }}
        </template>

        <template #preppendIcon>
          <UiIconsArrowTopRight />
        </template>
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .systemLayout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    color: #fff;

    > *:not(img) {
      z-index: 2;
      position: relative;
    }
  }

  .status {
    text-align: center;
    font-size: 128px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .content {
    margin-bottom: 40px;
    text-align: center;
    font-family: 'Gilroy';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .logo {
    margin-top: 70px;
    height: 119px;
    width: auto;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
</style>