<template>
  <label :class="$style.fieldGroup">
    <span
      v-if="label || $slots.label"
      :class="$style.label"
    >
      <slot v-if="$slots.label" name="label" />
      <template v-else>
        {{ label }}
      </template>
    </span>

    <span :class="[
      $style.field,
      rounded && $style.rounded,
      errorMessage && $style.hasError,
    ]">
      <input
        :type="localType"
        :placeholder="placeholder || ''"
        :class="[
          $style.input,
          isDisabled && $style.isDisabled,
        ]"
        autocomplete="off"
        v-model="value"
        @change="handleChange"
        @blur="handleBlur($event, true)"
        :disabled="isDisabled"
      >

      <span
        v-if="type === 'password'"
        :class="$style.eye"
        @click.prevent="changePasswordType"
      >
        <UiIconsEyeClosed v-show="localType === 'password'" />
        <UiIconsEyeOpened v-show="localType === 'text'"/>
      </span>
    </span>

    <span
      v-if="help"
      :class="$style.help"
    >
      {{ help }}
    </span>

    <span
      v-if="errorMessage && !help"
      :class="$style.error"
    >
      {{ errorMessage }}
    </span>
  </label>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { type FieldParams } from './';

const props = withDefaults(defineProps<FieldParams>(), {
  rounded: true,
  type: 'text',
  isDisabled: false,
});

const {
  value,
  errorMessage,
  handleChange,
  handleBlur,
} = useField(() => props.name);

const localType = ref<string>(props.type);
const changePasswordType = (): void => {
  localType.value = localType.value === 'password' ? 'text' : 'password';
};
</script>

<style lang="scss" module>
  .fieldGroup {
    position: relative;
    display: inline-flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: right;
    font-size: 12px;
    color: #FF3C77;
  }

  .help {
    margin-top: 3px;
    color: #71787F;
    font-size: 12px;
  }

  .label {
    margin-bottom: 10px;
    color: #596269;

    > div span, span {
      color: #71787F;
    }
  }

  .field {
    display: flex;
    position: relative;
    background: #F4F4F4;
    border: 1px solid #F4F4F4;
    width: 100%;
    height: 50px;

    &.rounded {
      border-radius: 25px;
    }

    &:not(.rounded) {
      border-radius: 14px;
    }

    &.hasError {
      border-color: #FF3C77;
    }
  }

  .eye {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 53px;
    height: 100%;
    cursor: pointer;
    transition: 200ms ease;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 19px;
      height: auto;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    appearance: none !important;
    border: none;
    box-shadow: none;
    padding: 15px 29px;
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    color: #121E29;
    background-color: transparent;

    &::placeholder {
      color: #85868E;
    }

    &.isDisabled {
      pointer-events: none;
      user-select: none;
      cursor: default;
      color: #71787F;
    }
  }
</style>.