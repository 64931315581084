<template>
  <div
    :class="$style.modal"
  >
    <div
      :class="{
        [$style.loader]: true,
        [$style.active]: isLoading,
      }"
    >
      <UiLoader :is-visible="true" />
    </div>
    <div
      v-if="!hideHead"
      :class="$style.head"
    >
      <div :class="$style.headTitle">
        <slot name="head">
          {{ label }}
        </slot>
      </div>

      <button
        :class="$style.closeButton"
        @click.prevent="close"
      >
        <UiIconsClose />
      </button>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { ModalEmits, ModalProps } from '@/components/ui/Modal/index.js';

withDefaults(
  defineProps<ModalProps>(),
  {
    hideHead: false,
    isLoading: false,
  },
);
const emit = defineEmits<ModalEmits>();

const close = (event?: Event) => {
  emit('close', event);
}
</script>

<style lang="scss" module>
.modal {
  position: relative;
  max-width: 498px;
  width: 100%;
  border-radius: 15px;
  background:#FFF;
  padding: 36px 46px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  gap: 0 26px;

  &Title {
    color: #121E29;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.closeButton {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 21px;
  margin-left: auto;
  transition: 200ms ease-in-out;
  cursor: pointer;
  color: #8D9398;
  background: #F4F4F4;
  box-shadow: none;
  border: none;

  svg {
    width: 12px;
    height: auto;
  }

  &:hover {
    opacity: .8;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.loader {
  position: absolute;
  z-index: 300;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: .2s opacity;
  pointer-events: none;
  background-color: rgb(var(--color-background-1));
  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
