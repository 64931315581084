export default defineNuxtRouteMiddleware(async (to) => {
  if (to.fullPath.startsWith('/reset-password/')) {
    const { $localePath } = useNuxtApp();
    const redirectTo = `/auth/change-password?token=${to.fullPath.split('/').at(-1)}`;
    return navigateTo($localePath(redirectTo));
  }

  if (to.fullPath.startsWith('/stripe/checkout/success')) {
    const { $localePath } = useNuxtApp();
    const redirectTo = '/payment/success';
    return navigateTo($localePath(redirectTo));
  }
});
