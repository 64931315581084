<template>
  <div
    :class="{
      [$style.modalLayer]: true,
      [$style.active]: isActiveModals
    }"
  >
    <div
      :class="$style.backdrop"
      @click="outerClick(displayModal.id, displayModal?.payload?.externalClick)"
    />
    <div :class="$style.module">
      <component
        :is="displayModal.component"
        v-if="isActiveModals"
        v-bind="displayModal.payload"
        :class="$style.modal"
        @close="closeModal(displayModal.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modals';
import { modalType, type Modal } from '@/stores/modals';
import PromocodeModal from '@/components/Promocode/PromocodeModal.vue';
import PaymentBalanceModal from '@/components/Payment/PaymentBalanceModal.vue';
import SubsrbRegModal from '@/components/Subscrb/SubsrbRegModal.vue';

const modalsStore = useModalStore();

const modalsMap = {
  [modalType.PROMOCODE_MODAL]: PromocodeModal,
  [modalType.PAYMENT_BALANCE]: PaymentBalanceModal,
  [modalType.REG_SUBSCRB]: SubsrbRegModal,
};

const modals = computed(() => modalsStore.list.map((modal) => ({
  ...modal,
  component: modalsMap[modal.type],
})));
const isActiveModals = computed(() => !!modals.value.length);

const displayModal = computed(() => modals.value?.[modals.value.length - 1] || null);

const closeModal = (modalId: Modal['id']) => {
  modalsStore.removeModal(modalId);
};

const outerClick = (modalId: Modal['id'], externalClick: boolean) => {
  if (!externalClick) {
    closeModal(modalId);
  }
}

window?.addEventListener('keydown', (e, modalId: Modal['id']) => {
  if (e.keyCode === 27) {
    closeModal(modalId);
  }
});

watch(displayModal, (newValue) => {
  if (newValue) {

    if (window.innerWidth > 767) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }

    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = '';
    document.body.style.paddingRight = '';
  }
});
</script>

<style lang="scss" module>
.modalLayer {
  pointer-events: none;
  overflow-y: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & > * {
    pointer-events: all;
  }

  &.active {
    pointer-events: all;

    .backdrop {
      opacity: 1;
      pointer-events: all;
    }

    .module {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

.backdrop {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(25, 26, 55, 0.50);
  opacity: 0;
  transition: 150ms ease-in-out;
  pointer-events: none;
}

.modal {
  width: 100%;
}

.module {
  z-index: 3;
  position: relative;
  transform: translateY(20px);
  width: 100%;
  max-width: 498px;
  opacity: 0;
  transition: 300ms linear;
  transition-delay: 75ms;
}
</style>
