<template>
  <label :class="$style.amountGroup">
    <span
      v-if="label || $slots.label"
      :class="$style.label"
    >
      <slot v-if="$slots.label" name="label" />
      <template v-else>
        {{ label }}
      </template>
    </span>

    <!-- <Money3Component
      :class="[
        $style.field,
        localValue > 0 && $style.hasValue,
        errorMessage && $style.hasError,
      ]"
      v-bind="moneyOptions"
      v-model.number="localValue"
    /> -->
    <ClientOnly>
      <input
        ref="amountField"
        type="text"
        autocomplete="off"
        v-model="localValue"
        :class="[
          $style.field,
          value > 0 && $style.hasValue,
          errorMessage && $style.hasError,
        ]"
      >
      <!-- <AutoNumericVue
        :class="[
            $style.field,
            localValue > 0 && $style.hasValue,
            errorMessage && $style.hasError,
          ]"
        v-model="localValue"
        :options="moneyOptions"
      /> -->
    </ClientOnly>
  </label>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import type { AmountFieldParams } from './index.js';
import AutoNumeric from 'autonumeric/dist/autoNumeric.min'

const moneyOptions = ref({
  suffixText: ' USD',
  decimalPlaces: 2,
  maximumValue: 9999999,
  minimumValue: 0,
  digitGroupSeparator: '',
  emptyInputBehavior: 'min',
});
const props = defineProps<AmountFieldParams>();
const localValue = ref(props.amount || 0);
const amountField = ref<HTMLDivElement | null>(null);
const anElement = ref();
const {
  value,
  errorMessage,
} = useField<number>(() => props.name, undefined, {
  syncVModel: 'amount',
});

watch(amountField, () => {
  if (amountField.value && !anElement.value) {
    anElement.value = new AutoNumeric(amountField.value, moneyOptions.value);
  }
});

watch(localValue, () => {
  value.value = +String(localValue.value).split(' ')[0] || 0;
});
</script>

<style lang="scss" module>
  .amountGroup {
    border-radius: 14px;
    border: 1px solid #F6F6F6;
    background: #F4F4F4;
    padding: 22px 26px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .label {
    color: rgba(22, 24, 41, 0.50);
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .field {
    color: #8D9398;
    font-family: 'Gilroy';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    transition: 200ms ease;

    &.hasValue {
      color: #293EFF;
    }

    &.hasError {
      color: #FF3C77;
    }
  }
</style>